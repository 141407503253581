<template>
    <div class="">
        <form id="formulaire" v-on:submit.prevent="onSubmit">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="order_id">FACTURE</label>
                        <input type="text" class="form-control form-control-sm"
                               name="order_id" id="order_id" v-model="crudform.order_id"
                        >
                    </div>

                    <div class="form-group">
                        <label for="amount">Montant</label>
                        <input type="text" class="form-control-sm form-control"
                               name="amount" id="amount" v-model="crudform.amount">
                    </div>

                    <div class="form-group">
                        <label for="reference">Référence</label>
                        <input type="text" class="form-control form-control-sm"
                               name="reference" id="reference" v-model="crudform.reference"
                        >
                    </div>

                    <div class="btn-group text-right">
                        <button class="btn btn-lg btn-dark rounded-0 text-right" > ENREGISTRER</button>
                    </div>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
    import axios from "axios";
    import $ from "jquery";
    export default {
        name: "Paiement",
        data: function() {
            return {
                crudform: {
                    id: 0,
                    order_id:'',
                    amount:0,
                    reference:'',
                },
            }
        },
        methods: {

            onSubmit() {
                var that = this;
                this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                    .then(value => {
                        console.log(value);
                        if (value == true) {
                            var link = that.BASE_URL + "/paiement/gotopaiement" ;
                            axios.post(link,$("#formulaire").serialize()).then((response)=> {
                                console.log(response)
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        // An error occurred
                    });
            },

        },
    }
</script>

<style scoped>

</style>
